<template>
    <layout-full-width :title="$tc('general')">

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        {{ $t('general-settings') }}
                    </div>
                    <div class="card-body">
                        {{ $t('general-settings-description') }}
                    </div>
                </div>
            </div>
        </div>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";

export default {
    name: "PageGeneral",
    components: {LayoutFullWidth}
}
</script>

<style lang="scss" scoped></style>